import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useActor } from '@xstate/react';
import { MetricMachineContext } from '../../Context/MetricFormContext';
import Textarea from '../../../../tailwind_common/Textarea';
// TODO: move feedback to tailwind
export var GenericFeedbackCard = function () {
    var metricService = useContext(MetricMachineContext);
    var current = useActor(metricService)[0];
    var message = current.context.message;
    var t = useTranslation().t;
    var Label = function () { return (React.createElement("span", { className: "relative flex w-full" }, t('feedbackModal.generic.question.label', 'More details (optional)'))); };
    return (
    // TODO: should be my-6, not w-9/10 (needs update for all steps)
    React.createElement("div", { className: "mb-6 flex w-9/10 flex-1 flex-col" },
        React.createElement("p", { className: "mx-0 my-5 text-base text-common-penguin mobile:mx-0 mobile:mb-5 mobile:mt-3.5" },
            React.createElement("span", { className: "font-normal" },
                t('feedbackModal.generic.question.promptText', 'Would you like to share any additional information to help us improve'),
                ' '),
            React.createElement("span", { className: "font-semibold" }, t('feedbackModal.generic.question.promptSubtext', 'checking symptoms or booking options?'))),
        React.createElement(Textarea, { containerClassName: "flex-1", placeholder: t('feedbackModal.generic.question.placeholder', 'Enter your response'), Label: Label, onChange: function (e) { return metricService.send({ type: 'SAVE', message: e.target.value }); }, value: message !== null && message !== void 0 ? message : '', id: "generic feedback" }),
        React.createElement("p", { className: "m-0 text-sm font-normal text-gray-500" }, t('feedbackModal.generic.question.footer', 'Do not enter personally identifiable information, e.g. Name.'))));
};
