var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import useWarningMessage from './WarningMessageHook';
import WarningMessage from './WarningMessage';
export var WarningBanner = function () {
    var t = useTranslation().t;
    var warningMessageConfig = useWarningMessage();
    return (React.createElement("div", { className: twMerge('mb-8 box-border flex w-full flex-col items-center gap-4 bg-banner-emergency px-6 py-4', 'mobile:mb-4 mobile:px-8 mobile:py-2') },
        React.createElement("p", { className: twMerge('text-center font-primary text-base font-normal tracking-tighter text-gray-600', 'mobile:text-start') }, t('conversationBanner.warning', 'Please note this is an automated chat, not a live person. If you have a medical emergency, call 911.')),
        warningMessageConfig && React.createElement(WarningMessage, __assign({}, warningMessageConfig))));
};
export default WarningBanner;
