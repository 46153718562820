import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useActor } from '@xstate/react';
import { twMerge } from 'tailwind-merge';
import { MetricMachineContext } from '../../Context/MetricFormContext';
import Textarea from '../../../../tailwind_common/Textarea';
import RadioGroup from '../../../../tailwind_common/RadioGroup';
var emptyOption = {
    value: '',
    label: '',
};
// TODO: move feedback to tailwind
export var PostIntentFeedbackCard = function () {
    var metricService = useContext(MetricMachineContext);
    var current = useActor(metricService)[0];
    var _a = current.context, message = _a.message, intent = _a.intent;
    var t = useTranslation().t;
    var Label = function () { return (React.createElement("span", { className: "relative flex w-full text-sm" }, t('feedbackModal.generic.question.label', 'More details (optional)'))); };
    var seekCareOptions = [
        {
            value: 'book_care',
            label: t('feedbackModal.postIntent.careOptions.bookCare', 'Book care through this site'),
        },
        {
            value: 'call_doctor',
            label: t('feedbackModal.postIntent.careOptions.callDoctor', 'Call the doctor’s office'),
        },
        {
            value: 'go_to_er_or_urgent_care',
            label: t('feedbackModal.postIntent.careOptions.EROrUrgentCare', 'Go to the ER or Urgent Care'),
        },
        {
            value: 'no_booking',
            label: t('feedbackModal.postIntent.careOptions.notBooking', 'I don’t plan to book care'),
        },
    ];
    var selectedSeekCareOption = seekCareOptions.find(function (opt) { return opt.value === intent; }) || emptyOption;
    return (
    // TODO: should be my-6, not w-9/10 (needs update for all steps)
    React.createElement("div", { className: "mb-6 flex w-9/10 flex-1 flex-col" },
        React.createElement("p", { className: "mx-0 my-5 text-base text-common-penguin mobile:mx-0 mobile:mb-5 mobile:mt-3.5" },
            React.createElement("span", { className: "font-normal" },
                t('feedbackModal.postIntent.question.promptText', 'How do you plan to'),
                ' '),
            React.createElement("span", { className: "font-semibold" }, t('feedbackModal.postIntent.question.promptSubtext', 'seek care?'))),
        React.createElement(RadioGroup, { options: seekCareOptions, selectedOption: selectedSeekCareOption, onChange: function (opt) { return metricService.send({ type: 'SAVE', intent: opt.value }); }, className: twMerge('mb-6 grid grid-cols-2 gap-4', 'mobile:grid-cols-1'), labelClassName: "text-sm" }),
        React.createElement(Textarea, { containerClassName: "flex-1", placeholder: t('feedbackModal.generic.question.placeholder', 'Enter your response'), Label: Label, onChange: function (e) { return metricService.send({ type: 'SAVE', message: e.target.value }); }, value: message !== null && message !== void 0 ? message : '', id: "generic feedback" }),
        React.createElement("p", { className: "m-0 text-sm font-normal text-gray-500" }, t('feedbackModal.generic.question.footer', 'Do not enter personally identifiable information, e.g. Name.'))));
};
