var _a;
import { MessageType } from './EventLog';
export var GaCategory;
(function (GaCategory) {
    GaCategory["CTA"] = "cta";
    GaCategory["PHONE"] = "phone";
    GaCategory["LOCATION"] = "location";
    GaCategory["UI"] = "ui";
    GaCategory["GENERAL"] = "general";
    GaCategory["FAILURE"] = "failure";
    GaCategory["OTHER"] = "other";
})(GaCategory || (GaCategory = {}));
export var EventCategory = (_a = {},
    // CTA
    _a[MessageType.APPOINTMENT_BOOKED] = GaCategory.CTA,
    _a[MessageType.CLICKED_PARTNER_NURSE_LINE] = GaCategory.CTA,
    _a[MessageType.PHONE_NUMBER_CLICK] = GaCategory.CTA,
    _a[MessageType.LINK_CLICK] = GaCategory.CTA,
    _a[MessageType.MAP_CLICK] = GaCategory.CTA,
    _a[MessageType.FIND_OTHER_LOCATIONS] = GaCategory.CTA,
    _a[MessageType.CLICKED_PARTNER_CARE_OPTION] = GaCategory.CTA,
    _a[MessageType.CLICKED_PARTNER_RESOURCE_CENTER] = GaCategory.CTA,
    _a[MessageType.CLICKED_PARTNER_EXTERNAL_LINK] = GaCategory.CTA,
    // UI
    _a[MessageType.TOGGLED_DROPDOWN_BLOCK] = GaCategory.UI,
    _a[MessageType.CONVERSATION_START] = GaCategory.UI,
    _a[MessageType.CONVERSATION_CLOSE] = GaCategory.UI,
    _a[MessageType.CLICKED_CONTINUE_TO_CHECK_SYMPTOMS] = GaCategory.UI,
    _a[MessageType.VIEW_RESULTS] = GaCategory.UI,
    _a[MessageType.APPOINTMENT_INITIATED] = GaCategory.UI,
    _a[MessageType.BUTTON_CLICK] = GaCategory.UI,
    _a[MessageType.CLICKED_CONVO_HEADER] = GaCategory.UI,
    _a[MessageType.SUBMITTED_BOOKING_INTENT] = GaCategory.UI,
    _a[MessageType.CLICKED_DONATE_PLASMA] = GaCategory.UI,
    _a[MessageType.ANSWER_IS_PROXY_USER] = GaCategory.UI,
    _a[MessageType.WIDGET_EXPANSION] = GaCategory.UI,
    _a[MessageType.WIDGET_WORKFLOW_CLICKED] = GaCategory.UI,
    // General
    _a[MessageType.SET_INITIAL_COMPLAINT] = GaCategory.GENERAL,
    // Location
    _a[MessageType.USER_POS] = GaCategory.LOCATION,
    _a[MessageType.LOCATION_SEARCH] = GaCategory.LOCATION,
    _a[MessageType.LOCATION_SEARCH_RESULT] = GaCategory.LOCATION,
    // Failure
    _a[MessageType.APPOINTMENT_FAILED] = GaCategory.FAILURE,
    // Other
    _a[MessageType.UNKNOWN_MESSAGE_TYPE] = GaCategory.OTHER,
    _a[MessageType.ANSWER_QUESTION] = GaCategory.OTHER,
    _a[MessageType.REDIRECT_INITIATED] = GaCategory.OTHER,
    _a);
