var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isSmallScreen } from '../../../util/AppHelpers';
import { SelectScore } from '../SelectScore/SelectScore';
import { ScoreSelectCES } from './CESScoreSelect';
import StarHover from './StarIcons/StarHover.svg';
import StarOutline from './StarIcons/StarOutline.svg';
import StarSelected from './StarIcons/StarSelected.svg';
import StarOutlineMobile from './StarIcons/StarOutlineMobile.svg';
import StarSelectedMobile from './StarIcons/StarSelectedMobile.svg';
import { CESMobileScoreSelect } from './CESMobileScoreSelect';
import '../MetricProps';
export var CESCard = function (props) {
    var score = props.score, onSelect = props.onSelect, dynamicConfig = props.dynamicConfig;
    var t = useTranslation().t;
    var icons = isSmallScreen()
        ? {
            defaultIcon: StarOutlineMobile,
            selectIcon: StarSelectedMobile,
            hoverIcon: StarSelectedMobile,
        }
        : { defaultIcon: StarOutline, selectIcon: StarSelected, hoverIcon: StarHover };
    var selectHandler = function (score) {
        onSelect(score);
    };
    return (React.createElement(SelectScore, { scoreSelectorProps: {
            scores: Array.from(Array(7).keys()).map(function (zeroScore) { return (__assign({ score: zeroScore + 1 }, icons)); }),
            score: score,
            onSelect: selectHandler,
        }, promptText: (dynamicConfig === null || dynamicConfig === void 0 ? void 0 : dynamicConfig.ces_question_text) ||
            t('feedbackModal.ces.promptText', 'How much do you agree or disagree with the statement:'), promptSubtext: (dynamicConfig === null || dynamicConfig === void 0 ? void 0 : dynamicConfig.ces_question_subtext) ||
            t('feedbackModal.ces.promptSubtext', 'Using this digital navigator was easy.'), lowScoreText: t('feedbackModal.ces.lowScoreText', 'STRONGLY DISAGREE'), highScoreText: t('feedbackModal.ces.highScoreText', 'STRONGLY AGREE'), ScoreSelectorComponent: isSmallScreen() ? CESMobileScoreSelect : ScoreSelectCES }));
};
